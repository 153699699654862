import { useEffect, useState } from 'react';
import '../style/style.css';
import { useUser } from '../Main';

import { Link, Outlet, useOutletContext, useParams } from 'react-router-dom';
import { NewNotification, NotificationType } from './Notifications';

export default function Connections({connectionType}:{connectionType : string}) {

    const {username} = useUser();
    const params = useParams();
    const [connections, setConnections] = useState<{username:string, avi:string}[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const bootlickAll = () =>{
        alert("bootlick all pressed!")
        fetch("/mass-bootlick", {
            method: "POST",
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8'},
            body: JSON.stringify({username:username, toBootlick:connections})
        })
        .then(res =>res.json())
        .then(data=>{
            if(data.status !== 200){
                alert("Yowza! Something went wrong. Bootlicking unsuccessful :( tell David");
                return;
            }
            if(data.toBootlick.length < 1){
                alert("there is nobody else to bootlick that you're not already bootlicking silly!")
                return;
            }
            alert(`bootlick all... SUCCEEDED! But at what cost?`)
            data.toBootlick.forEach((newBootlicker: string)=>{
                alert(`now bootlicking: ${newBootlicker}`)
                NewNotification(username!, 
                    newBootlicker!,
                    "",
                    '',
                    NotificationType.Follow);
            })
    })
        
    }
    useEffect(()=>{
        const getPostData = async () => {
            const res = await fetch(`/get-${connectionType}?id=${params.id}`);
            const data = await res.json();            
            if(!data) return;

            const aviRes = await fetch(`/get-avis-for-users`, {
                    method: "post",
                    headers: { 
                      'Accept': 'application/json',
                      'Content-Type': 'application/json; charset=UTF-8'},
                    body: JSON.stringify({"users":data})
            })
            const aviData = await aviRes.json();
            setConnections(aviData);
            setLoaded(true);
        }
        getPostData();
    },[params])

    return(
            <div>
                <div style={{textAlign:"left", marginBottom:"50px"}}>
                    <button onClick={bootlickAll}>bootlick all</button>
                </div>
                    {loaded ? 
                        connections.map((obj)=><div key={obj.username} className='mini-user-card'>
                                                <img src={obj.avi} className='mini-user-card-img'/> &nbsp;
                                                <Link to={`/~/u/${obj.username}`} key={obj.username}>{obj.username}</Link>
                                            </div>)
                        :
                        <img src="/img/loading-hourglass.gif"/>
                    }
            </div>
    )
}
