import { useEffect, useState } from 'react';
// import '../style/style.css';

interface IParameter {
    name: string;
    desc: string;
}

export default function APIDocs() {

;
    useEffect(()=>{

    },[])

    return(
            <div style={{backgroundColor:"#151515", marginTop:"-10px", height:"100vh", color:"#ccc"}}>
            <div style={{margin:"10px", overflowY:"scroll", height:"calc(100vh - 20px)"}}>
                    <h1>API Documentation</h1>
                    <b>Note all post HTTP requests MUST include cookies. E.g. in Python: </b><br/>
                    <code>requests.post("/api/route", cookies=session.cookies, json=myDictObject)</code>
                    <h2>Full python example</h2>
                    <code>
                    import requests<br/>
<br/>
                    # simple ping, gets the status code<br/>
                    res = requests.get('https://www.davidsocial.com/api/ping')<br/>
                    print(res.status_code)<br/>
<br/>
                    # now I want to do something more complicated, first I must login<br/>
                    login_credentials = {`{"username":"David", "password":"nope"}`}<br/>
                    session = requests.post('https://www.davidsocial.com/login', json=login_credentials)<br/>
<br/>
                    if(res.status_code != 200):<br/>
                    &nbsp;&nbsp;print("error logging in :(")<br/>
                    &nbsp;&nbsp;exit()<br/>
<br/>
                    # time to update my status :)<br/>
                    status = {`{"status":"testy test test"}`}<br/>
                    res = requests.post("https://www.davidsocial.com/api/update-status", json=status, cookies=session.cookies)<br/>
                    if(res.status_code == 200):<br/>
                    &nbsp;&nbsp;print("updated status :)")<br/>
                    else:<br/>
                    &nbsp;&nbsp;print("did not update status :(")<br/>
                    </code>
                    <h2>POST requests</h2>
                    <p/>

                    <BLOCK route="/login" desc="login! **does not require cookies** but returns a cookie!!! 🍪" body={[{name:"username",desc: "name of user"}, {name:"password",desc:"super secret password keep safe only u and David know this"}]}/>

                    <BLOCK route="/api/global-feed" desc="gets the global feed" body={[{name:"[OPTIONAL] window",desc: "number of days back to grab posts"}]}/>

                    <BLOCK route="/api/bootlicker-feed" desc="get bootlicker feed" body={[{name:"[OPTIONAL] window",desc: "number of posts to load"}]}/>

                    <BLOCK route="/api/new-post" desc="makes a new post" body={[{name:"text",desc: "main content of the new post"},{name:"replyTo",desc: "post id of what this post is in reply to. Set as 0 for a non-reply general post."}]}/>

                    <BLOCK route="/api/delete-post" desc="deletes a post" body={[{name:"id",desc: "the post id to delete"}]}/>
                    
                    <BLOCK route="/api/like-post" desc="likes a post" body={[{name:"id",desc: "post id to like"}]}/>

                    <BLOCK route="/api/my-notifications" desc="gets notifications for current user"/>

                    <BLOCK route="/api/public-set-ticker-text" desc="updates the ticker" body={[{name:"text",desc: "the content to update the ticker with"}]}/>

                    <BLOCK route="/api/update-status" desc="Updates user status." body={[{name:"status",desc: "the status to update"}]}/>
                    <hr/>
                    <h2>GET requests</h2>
                    Some GET requests have parameters. These can be placed in the route, e.g. in Python: <code>requests.get("/api/my-route?paramName=paramValue", cookies=session.cookies)</code>
                    <p/>
                    <BLOCK route="/api/version" desc="gets current version of David Social"/>

                    <BLOCK route="/api/ping" desc="pings the server for debugging purposes"/>


                    <BLOCK route="/api/avi-url" desc="gets url for a user's avatar" params={[{name:"username",desc: "name of user to query"}]}/>

                    <BLOCK route="/api/user-posts" desc="obtain all posts made by a particular user" params={[{name:"username",desc: "name of user to query"}]}/>

                    <BLOCK route="/api/replies" desc="get replies to a particular post" params={[{name:"id",desc: "the id of the post to query"}]}/>
                    
                    <BLOCK route="/api/get-post" desc="retrieves an individual post" params={[{name:"id",desc: "post id"}]}/>
                    
                    <BLOCK route="/api/user-list" desc="gets a list of all users on the network"/>
                    
                    <BLOCK route="/api/bootlickers" desc="gets a list of a user's bootlickers" params={[{name:"username",desc: "name of user to query"}]}/>
                    
                    <BLOCK route="/api/bootlicking" desc="get a list of users that User X is bootlicking" params={[{name:"username",desc: "name of user to query"}]}/>

                    <BLOCK route="/api/liked-by" desc="gets a list of people who liked a post" params={[{name:"id",desc: "post id"}]}/>

                    <BLOCK route="/api/profile" desc="gets a user profile" params={[{name:"username",desc: "name of user to view"}]}/>

                    <BLOCK route="/get-ticker-text" desc="retrieves the current text of ticker" />

                    <BLOCK route="/api/pet-cat" desc="pets the cat (registers +1 pat)" />
                    <BLOCK route="/api/get-cat-pets" desc="gets the number of times the cat was pet" />
                    <BLOCK route="/api/get-status" desc="gets the status of a user" params={[{name:"username",desc: "name of user to view"}]} />
                    
                    {/* <BLOCK route="/api/" desc="" params={[{name:"",desc: ""}]}/>
                    <BLOCK route="/api/" desc="" params={[{name:"",desc: ""}]}/>
                    <BLOCK route="/api/" desc="" params={[{name:"",desc: ""}]}/> */}
                    
            </div>

            
            </div>
    )
}

function BLOCK(props: {route: string, desc:string, body?:IParameter[], params?:IParameter[]}){
    return(
        <div style={{padding:"10px", margin:"10px", border: "1px solid #333"}}>
            <span style={{fontSize:"20px", fontWeight:"bold", backgroundColor:"#333", color:"rgb(171, 200, 171)"}}><code>{props.route}</code></span><p/>
            <i>{props.desc}</i>
            
            {props.body? 
                <div>
                <p/>
                <span style={{fontWeight:"bold"}}>@body</span>
                <div style={{marginLeft:"10px"}}>
                    {props.body.map(item=><div><code>{item.name}</code>: <span style={{color:"grey"}}>{item.desc}</span></div>) }
                </div>
                </div>
                : 
                ""}
            {props.params? 
                <div>
                    <p/>
                    {props.params.map(item=><div><b>@param</b> <code>{item.name}</code>: {item.desc}</div>) }
                </div>
                : 
                ""}
        </div>
    )
}
